import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import StoreDetail from './StoreDetail';
import StoreQuery from './StoreQuery.gql';
import { StoreContext } from '../StoreHandler/StoreContext';
import { createStoreSlug } from '../StoreLocator/StoreList';

const Wrapper = styled(MaxWidth)`
  margin: 2rem;
`;

// Define explicit mappings for edge cases
const STORE_SLUG_TO_ID_MAP = {
  vemdalen: 59,
  'malmback-huvudkontor-ej-butik': 39,
  'goteborg-backaplan': 3,
  'goteborg-askim': 56,
  // Add more special cases as needed
};

// Enhanced slug creator and matcher function
const matchSlugToStore = (storeParam, storeList) => {
  // Check explicit mapping first
  if (STORE_SLUG_TO_ID_MAP[storeParam]) {
    return STORE_SLUG_TO_ID_MAP[storeParam];
  }

  // If it's already numeric, return it
  if (/^\d+$/.test(storeParam)) {
    return parseInt(storeParam, 10);
  }

  // Try to find an exact match by store_slug first
  const exactSlugMatch = storeList.find(
    (store) => store.store_slug === storeParam
  );

  if (exactSlugMatch) {
    return parseInt(exactSlugMatch.extra?.id || exactSlugMatch.id, 10);
  }

  // Try to find an exact match by created slug
  const exactCreatedSlugMatch = storeList.find(
    (store) => createStoreSlug(store.name) === storeParam
  );

  if (exactCreatedSlugMatch) {
    return parseInt(
      exactCreatedSlugMatch.extra?.id || exactCreatedSlugMatch.id,
      10
    );
  }

  // Try to find a partial match (for hyphenated names like bollnas-tradgardsmobler)
  const partialMatch = storeList.find((store) => {
    const storeSlug = createStoreSlug(store.name);
    // Check if the slug starts with the parameter or vice versa
    return storeParam.startsWith(storeSlug) || storeSlug.startsWith(storeParam);
  });

  if (partialMatch) {
    return parseInt(partialMatch.extra?.id || partialMatch.id, 10);
  }

  // If we still can't find it, try a more lenient match
  const lenientMatch = storeList.find((store) => {
    const cleanStoreName = store.name.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const storeSlug = createStoreSlug(cleanStoreName);
    return storeParam.includes(storeSlug) || storeSlug.includes(storeParam);
  });

  if (lenientMatch) {
    return parseInt(lenientMatch.extra?.id || lenientMatch.id, 10);
  }

  // No match found
  return null;
};

export default function Store(props) {
  const {
    match: {
      params: { id: storeParam },
    },
  } = props;

  const { storeList } = useContext(StoreContext);
  const [numericId, setNumericId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Find numeric ID from slug or use the ID directly if it's already numeric
  useEffect(() => {
    if (storeList && storeList.length > 0) {
      const matchedId = matchSlugToStore(storeParam, storeList);
      setNumericId(matchedId);
      setIsLoading(false);
    }
  }, [storeParam, storeList]);

  // Only run the query when we have a numeric ID
  const { data, loading, error } = useQuery(StoreQuery, {
    variables: { id: numericId },
    skip: numericId === null, // Skip the query until we have a numeric ID
  });

  if (isLoading || (storeList?.length > 0 && numericId === null)) {
    return <Wrapper>Finding store...</Wrapper>;
  }

  if (numericId === null) {
    return <Wrapper>Could not find a store matching '{storeParam}'</Wrapper>;
  }

  if (loading) return <Wrapper>Loading…</Wrapper>;
  if (error) return <Wrapper>Something went wrong: {error.message}</Wrapper>;

  const { store } = data || {};

  // If we couldn't find a store with this slug/id
  if (!store) {
    return <Wrapper>No store found!</Wrapper>;
  }

  return <StoreDetail store={store} storeId={numericId} />;
}
