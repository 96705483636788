import React from 'react';
import { styled } from 'linaria/react';
import { Intl } from '@jetshop/intl';
import { ReactComponent as SearchSvg } from '../../svg/Search.svg';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  padding: 0 24px 12px 12px;

  p {
    margin-bottom: 8px;
    font-size: 14px;
  }

  ${theme.below.md} {
    padding: 24px 12px 12px 12px;
  }
`;

const SearchContainer = styled('div')`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 44px;
    padding: 0 48px 0 24px;

    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    outline: none;

    font-family: 'Source Sans Pro', sans-serif;

    background: #f7f7f7;
  }

  svg {
    position: absolute;
    top: 13px;
    right: 12px;

    stroke: transparent;
  }
`;

const StoreSearch = ({ search, setSearch }) => (
  <Wrapper>
    <SearchContainer>
      <Intl>
        {(t) => (
          <input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={t('Search store name or city, ex Stockholm')}
          />
        )}
      </Intl>
      <SearchSvg />
    </SearchContainer>
  </Wrapper>
);

export default StoreSearch;
