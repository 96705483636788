import t from '@jetshop/intl';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { StoreMarker } from './StoreMarker';
import { LocationStateContext } from './StoreLocator';
import UserLocation from './UserLocation';
import { TrendLink } from '../ui/Button';
import { theme } from '../Theme';

const Flex = styled('div')`
  flex: 1;
  display: flex;
  justify-content: space-between;

  ${theme.below.sm} {
    flex-direction: column;
  }
`;

const StoreListContainer = styled('ul')`
  height: calc(100% - 94px);
  overflow-y: auto;
  padding: 0 12px 12px;

  .highlight {
    background: yellow;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const StoreContainer = styled('li')`
  width: 100%;
  background: ${theme.colors.white};
  padding: 20px;
  margin: 8px 0;

  border: 1px solid #e0e0e0;

  font-size: 14px;
  line-height: 1.4;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  > div {
    display: flex;
  }

  :focus {
    outline: 1px solid ${theme.colors.blue};
  }

  button {
    height: 40px;
  }

  p {
    margin: 0;
  }
`;

const FlexApart = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
`;

const ToStoreLink = styled(TrendLink)`
  max-width: 148px !important;
  margin: 0 0 0 auto;
  height: 40px;
  ${theme.below.sm} {
    margin: 18px 0 0;
  }
`;

const humanDistance = (distance) =>
  distance > 10
    ? t('{km} km', { km: Math.round(distance) })
    : distance > 1
    ? t('{km} km', { km: distance.toFixed(1) })
    : t('{m} m', { m: Math.round(distance * 1000) });

// Helper function to create a URL-friendly slug from store name
const createStoreSlug = (name) => {
  return name
    .toLowerCase()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-');
};

// Add this helper function
const getStoreSlug = (store) => {
  // Define explicit mappings for edge cases
  const storeIdToSlugMap = {
    59: 'vemdalen',
    39: 'malmback-huvudkontor-ej-butik',
    3: 'goteborg-backaplan',
    56: 'goteborg-askim',
    // Add more special cases as needed
  };

  const storeId = store.extra?.id || store.id;

  // Use explicit mapping if available
  if (storeIdToSlugMap[storeId]) {
    return storeIdToSlugMap[storeId];
  }

  // Otherwise fall back to regular slug generation
  return store.store_slug || createStoreSlug(store.name);
};

const Store = ({
  storeNr,
  distances,
  store: { id, name, address1, extra = {}, storeInfo = {}, ...store },
}) => {
  const locationState = useContext(LocationStateContext);
  const active = id === locationState.activeLocation;

  const storeId = extra.id || id;
  const storeSlug = getStoreSlug({ id, name, extra, ...store });

  return (
    <StoreContainer
      onMouseEnter={() => locationState.setHighlightedLocation(id)}
      onMouseLeave={() => locationState.setHighlightedLocation(null)}
    >
      <div>
        <StoreMarker nr={storeNr} active={active} />
        <Flex>
          <FlexApart>
            <p>
              <strong dangerouslySetInnerHTML={{ __html: name }} />
            </p>
            <div>
              {storeInfo?.address ? (
                <div>
                  <p>{storeInfo.address}</p>
                  <p>
                    {storeInfo.zip} {storeInfo.city}
                  </p>
                  <p>
                    {t('Telefon')}: {storeInfo.phone}
                  </p>
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: extra?.address1 }} />
              )}
              {distances[storeId] && (
                <p style={{ color: '#8a8a8a' }}>
                  {t('Distance')}: {humanDistance(distances[storeId])}
                </p>
              )}
            </div>
          </FlexApart>
          <ToStoreLink to={`/butiker/${storeSlug}`} data-id={storeId}>
            {t('Om oss')}
          </ToStoreLink>
        </Flex>
      </div>
    </StoreContainer>
  );
};

const StoreList = ({ stores, setUserLocation, distances }) => {
  const sortedStores = stores.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  return (
    <StoreListContainer>
      <UserLocation setUserLocation={setUserLocation} />
      {sortedStores.map((store, index) => (
        <Store
          key={store.id || index}
          storeNr={index + 1}
          store={store}
          distances={distances}
        />
      ))}
    </StoreListContainer>
  );
};

export default StoreList;
// Export the slug function so it can be used elsewhere
export { createStoreSlug };
