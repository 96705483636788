import React, { useState, useMemo, useEffect } from 'react';

import { useLocation } from 'react-router';
import { trackPageEvent } from '@jetshop/core/analytics/tracking';
import { useTracker } from '@jetshop/core/analytics/Analytics';

import { styled } from 'linaria/react';
import { fuzzySearch } from '@jetshop/ui/fuzzySearch';
import { Above } from '@jetshop/ui/Breakpoints';
import { distance } from '@jetshop/core/helpers/distance';

import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import storeLocatorQuery from './StoreLocator.gql';
import StoreSearch from './StoreSearch';
import StoreList from './StoreList';
import StoreMap from './StoreMap';
import { useLocationState } from './useLocationState';

import Header from '../ui/Header';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet-async';

import { theme } from '../Theme';

const Flex = styled('div')`
  display: flex;

  h1 {
    margin-bottom: 0.5em;
  }

  ${theme.below.md} {
    flex-direction: column;
  }
`;

const StoreLocatorWrapper = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const StoreLocatorContent = styled(Flex)`
  height: 80vh;
  padding-top: 24px;
  padding-bottom: 24px;

  ${theme.below.md} {
    height: unset;
    padding-top: 12px;
  }
`;

const SearchAndListContainer = styled('div')`
  height: 100%;

  ${theme.above.md} {
    width: 40%;
  }
`;

export const LocationStateContext = React.createContext(null);

const StoreLocator = () => {
  const track = useTracker();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    track(trackPageEvent({ pathname: `${pathname}` }));
  }, [track, pathname]);

  const locationState = useLocationState();
  const [search, setSearch] = useState('');
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [closestStore, setClosestStore] = useState(null);
  const [distances, setDistances] = useState({});

  const genDistances = (stores) => {
    if (userLocation.latitude == null) return;

    let distances = {};
    let closestStore = {};
    stores.forEach((store) => {
      const thisDistance = distance(userLocation, store.coordinates);

      distances[store.id] = thisDistance;

      // If we haven't got a store in the closestStore obj yet, set it to this one
      if (!closestStore.distance) {
        closestStore = {
          ...store,
          distance: thisDistance,
        };
        return;
      }

      // If this store is closer than the one currently stored in closestStore,
      // override it
      if (thisDistance < closestStore.distance) {
        closestStore = { ...store, distance: thisDistance };
      }
    });

    setDistances(distances);
    setClosestStore(closestStore);
  };

  const { data = {}, loading = true } = useQuery(storeLocatorQuery);

  const stores = useMemo(() => {
    if (!data.stores) return [];

    const unorderedStores = search.length
      ? fuzzySearch({
          data: data.stores,
          keys: ['name', 'address1'],
          searchTerm: search,
        })
      : [...data.stores];
    return unorderedStores.sort((a, b) => (a.city < b.city ? -1 : 1));
  }, [search, data]);

  if (loading) return <LoadingPage />;

  if (userLocation.latitude && !closestStore) genDistances(data.stores);

  const { page } = data;

  const storeSearchProps = { search, setSearch };
  const storeListProps = {
    stores,
    userLocation,
    setUserLocation,
    distances,
  };
  const storeMapProps = {
    stores,
    userLocation,
    closestStore,
    distances,
  };

  console.log('stores', stores);

  return (
    <>
      <Helmet>
        <title>Hitta din butik</title>
        <meta
          name="description"
          content="Möbelmästarna har ca 30 möbelbutiker över hela Sverige där varje butik har ett unikt och utvalt sortiment av möbler i hög kvalitet."
        />
      </Helmet>
      <Header
        title={page.name}
        image={page.images}
        breadcrumbText={page.breadcrumbText}
      />
      <StoreLocatorWrapper>
        <StoreLocatorContent>
          <LocationStateContext.Provider value={locationState}>
            <Above breakpoint="md">
              {(matches) =>
                matches ? (
                  <>
                    <StoreMap {...storeMapProps} />
                    <SearchAndListContainer>
                      <StoreSearch {...storeSearchProps} />
                      <StoreList {...storeListProps} />
                    </SearchAndListContainer>
                  </>
                ) : (
                  <>
                    <StoreMap {...storeMapProps} />
                    <StoreSearch {...storeSearchProps} />
                    <StoreList {...storeListProps} />
                  </>
                )
              }
            </Above>
          </LocationStateContext.Provider>
        </StoreLocatorContent>
      </StoreLocatorWrapper>
    </>
  );
};

export default StoreLocator;
