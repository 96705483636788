import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { Above } from '@jetshop/ui/Breakpoints';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { components } from '../DynamicContent';

import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';

const ImageHeaderWrapper = styled(Image)`
  position: relative;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid #e8e8e8;
  /* height: 220px; */

  ${theme.above.md} {
    /* height: 305px; */
  }
  [data-flight-image-children] {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;


export const ColorHeaderWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: ${theme.colors.white};
  min-height: 480px;
  margin-bottom: 0;

  h1 {
    color: ${theme.colors.black};
    margin-top: 3rem;
  }

  ${theme.below.md} {
    min-height: 100px;
  }

  ${'' /* ${theme.below.sm} {
    padding-bottom: 40%;
  } */}
`;

const HeaderContainer = styled(MaxWidth)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &.bottom {
    justify-content: flex-end;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: absolute;
  background-color: ${theme.colors.white};
  padding-left: 2rem;
  top: 12px;
  left: 12px;
  padding: 0 2rem;

  a,
  li {
    font-size: 10px;
    font-weight: 700;
    font-family: ${theme.fonts.primary};
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.6px;
    line-height: 1.6;
    color: ${theme.colors.lightGrey};
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ${theme.above.md} {
    top: 1rem;
    left: 0rem;

    a,
    li {
      font-size: 12px;
    }
  }
`;

const DescriptionBox = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 2rem;
  bottom: 0;
  width: 50%;
  max-width: 550px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 2rem;

  h1 {
    color: ${theme.colors.black};
  }
`;

const Title = styled('h1')`
  color: ${theme.colors.white};
  font-size: 40px !important;
  font-weight: 700;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02);
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.2px;
  margin: 0;

  ${theme.above.md} {
    font-size: ${theme.fontSizes[7]};
  }

  ${theme.above.lg} {
    font-size: 40px;
  }

  @media screen and (max-width: 500px) {
    font-size: 30px !important;
  }
`;

const CategoryText = styled('div')`
  color: ${theme.colors.black};
  font-size: 14px;
  padding: 1rem 0 0 !important;
  line-height: 1.4;

  h2 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ${theme.above.lg} {
    font-size: 16px;
  }

  p + p {
    margin-top: 0.75rem;
  }
`;

const HeaderContent = ({ breadcrumbProps, title, content, image }) => {

    return (
    <HeaderContainer className={image?.length < 1 && 'bottom'}>

      <StyledBreadcrumbs className="" {...breadcrumbProps} />

      {content ? (

        <Above breakpoint="md">

          {(matches) =>

            matches ? (

              <DescriptionBox>
                <Title data-testid="page-header">{title}</Title>
                {content && (
                  <CategoryText
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                )}
              </DescriptionBox>
            ) : (
              <Title data-testid="page-header">{title}</Title>
            )

          }
        </Above>
      ) : (

        <Title data-testid="page-header">{title}</Title>
      )}
    </HeaderContainer>
  );
};

const Header = ({ image, title, breadcrumbText, parents, content }) => {

  const breadcrumbProps = {
    breadcrumbText: breadcrumbText,
    parents,
  };

  if (content?.includes('[customHeader]')) {
    return <Shortcodes content={content} components={components} />;

  } else {

    return image && image.length > 0 ? (

      <Above breakpoint="sm">
        {(matches) =>
          matches ? (

            <ImageHeaderWrapper aspect="4:1" src={image} cover sizes={[1, 1, 1.5, 2]} >
                <HeaderContent breadcrumbProps={breadcrumbProps} title={title} content={content} image={image} />
            </ImageHeaderWrapper>

          ) : (

            <ImageHeaderWrapper aspect="5:3" src={image} cover>
                <HeaderContent breadcrumbProps={breadcrumbProps} title={title} image={image} />
            </ImageHeaderWrapper>
          )
        }
      </Above>

    ) : (

      <ColorHeaderWrapper>
        <HeaderContent breadcrumbProps={breadcrumbProps} title={title} content={content} image={image} />
      </ColorHeaderWrapper>
    );
  }
};

export default Header;
