import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from '@react-google-maps/api';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { LocationStateContext } from './StoreLocator';
import { useMapState } from './useMapState';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';

import { theme } from '../Theme';
import { StoreContext } from '../StoreHandler/StoreContext';

const Wrapper = styled('div')`
  ${theme.above.md} {
    flex: 1;
  }

  ${theme.below.md} {
    padding: 0 12px;
  }

  .map-container {
    min-height: 100%;

    ${theme.below.md} {
      height: 50vh;
    }
    .gm-style-moc {
      display: none;
    }
  }
`;

const LoadingElement = () => <div style={{ height: '100%' }}></div>;

const StoreMap = ({
  stores,
  userLocation,
  closestStore,
  activeStore,
  long,
  lat,
}) => {
  const ref = useRef();
  const locationState = useContext(LocationStateContext);
  const { isOpen, infoId, showInfo } = useMapState();
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const { storeList } = useContext(StoreContext);

  const { googleMapsApiKey } = useShopConfig();

  throwErrorInDev(
    typeof googleMapsApiKey === 'undefined',
    'Make sure googleMapsApiKey is defined in your shop.config.js. See https://developers.google.com/maps/documentation/javascript/get-api-key'
  );

  // useless comment

  useEffect(() => {
    if (storeList && mapsLoaded) {
      // Stores are loaded, fit bounds
      const bounds = new window.google.maps.LatLngBounds();
      storeList.map((store) =>
        bounds.extend(
          new window.google.maps.LatLng(
            store?.extra?.coordinates?.latitude,
            store?.extra?.coordinates?.longitude
          )
        )
      );

      // Don't zoom in too far on only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new window.google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new window.google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }

      ref.current.fitBounds(bounds);
    }
  }, [mapsLoaded, storeList]);

  useEffect(() => {
    if (closestStore && mapsLoaded) {
      // User has shared its location and the closest store has been calculated
      const bounds = new window.google.maps.LatLngBounds();

      bounds.extend(
        new window.google.maps.LatLng(
          userLocation.latitude,
          userLocation.longitude
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          closestStore.coordinates.latitude,
          closestStore.coordinates.longitude
        )
      );

      ref.current.fitBounds(bounds);
    }
  }, [userLocation, closestStore, mapsLoaded]);

  return (
    <Wrapper className="storemap-wrapper">
      <>
        <LoadScript
          loadingElement={<LoadingElement />}
          preventGoogleFontsLoading={true}
          googleMapsApiKey={googleMapsApiKey}
        >
          <GoogleMap
            defaultZoom={6}
            mapContainerClassName="map-container"
            defaultCenter={{ lat: 59.247948, lng: 14.755806 }}
            onLoad={(map) => {
              ref.current = map;
              setMapsLoaded(true);
            }}
          >
            <>
              {userLocation &&
                userLocation.latitude &&
                userLocation.longitude && (
                  <Marker
                    position={{
                      lat: userLocation.latitude,
                      lng: userLocation.longitude,
                    }}
                  />
                )}

              {mapsLoaded &&
                storeList.map((store, index) => {
                  if (store?.extra === undefined) return null;
                  const isActive =
                    activeStore ??
                    store?.extra.id === locationState.activeLocation;

                  return (
                    <React.Fragment key={store?.extra.id}>
                      <Marker
                        cursor={'pointer'}
                        clickable={true}
                        icon={{
                          path: 'M23.011 12.068C23.011 20.7463 11.8531 28.1849 11.8531 28.1849C11.8531 28.1849 0.695312 20.7463 0.695312 12.068C0.695313 9.10875 1.87087 6.27071 3.96337 4.17821C6.05587 2.08571 8.89391 0.910156 11.8531 0.910156C14.8124 0.910156 17.6504 2.08571 19.7429 4.17821C21.8354 6.27071 23.011 9.10875 23.011 12.068Z',
                          fillColor: isActive ? '#911827' : 'white',
                          fillOpacity: 1,
                          strokeColor: '#911827',
                          anchor: new window.google.maps.Point(12, 32),
                          scale: isActive ? 1.25 : 1.2,
                          labelOrigin: new window.google.maps.Point(12, 14),
                        }}
                        zIndex={index}
                        position={{
                          lat: lat ? lat : store?.extra?.coordinates.latitude,
                          lng: long
                            ? long
                            : store?.extra?.coordinates.longitude,
                        }}
                        label={{
                          text: `${index + 1}`,
                          color: isActive ? 'white' : '#911827',
                          fontSize: '16px',
                        }}
                        onMouseOver={() => {
                          locationState.setHighlightedLocation(store?.extra.id);
                        }}
                        onMouseOut={() =>
                          locationState.setHighlightedLocation(null)
                        }
                        onClick={() => showInfo(store?.extra.id)}
                      />
                      {isOpen && infoId === store?.extra.id && (
                        <InfoWindow
                          key={`infowindow-${store?.extra.id}`}
                          onCloseClick={showInfo}
                          position={{
                            lat: store?.extra.coordinates.latitude,
                            lng: store?.extra.coordinates.longitude,
                          }}
                          options={{
                            pixelOffset: new window.google.maps.Size(50, 0),
                          }}
                        >
                          <div
                            style={{
                              background: 'white',
                              padding: '1em',
                              minWidth: '200px',
                              fontSize: '14px',
                              lineHeight: 1.5,
                            }}
                          >
                            <p>
                              <strong
                                dangerouslySetInnerHTML={{ __html: store.name }}
                              />
                            </p>
                            {store?.storeInfo && store?.storeInfo?.address ? (
                              <div>
                                <p>{store?.storeInfo?.address}</p>
                                <p>
                                  {store?.storeInfo?.zip}{' '}
                                  {store?.storeInfo?.city}
                                </p>
                                <p>Telefon: {store?.storeInfo?.phone}</p>
                              </div>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: store?.extra?.address1,
                                }}
                              />
                            )}
                          </div>
                        </InfoWindow>
                      )}
                    </React.Fragment>
                  );
                })}
            </>
          </GoogleMap>
        </LoadScript>
      </>
    </Wrapper>
  );
};

export default StoreMap;
